import * as _isGlob2 from "is-glob";

var _isGlob = "default" in _isGlob2 ? _isGlob2.default : _isGlob2;

import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _os2 from "os";

var _os = "default" in _os2 ? _os2.default : _os2;

var exports = {};
var isGlob = _isGlob;
var pathPosixDirname = _path.posix.dirname;
var isWin32 = _os.platform() === "win32";
var slash = "/";
var backslash = /\\/g;
var escaped = /\\([!*?|[\](){}])/g;
/**
 * @param {string} str
 * @param {Object} opts
 * @param {boolean} [opts.flipBackslashes=true]
 */

exports = function globParent(str, opts) {
  var options = Object.assign({
    flipBackslashes: true
  }, opts); // flip windows path separators

  if (options.flipBackslashes && isWin32 && str.indexOf(slash) < 0) {
    str = str.replace(backslash, slash);
  } // special case for strings ending in enclosure containing path separator


  if (isEnclosure(str)) {
    str += slash;
  } // preserves full path in case of trailing path separator


  str += "a"; // remove path parts that are globby

  do {
    str = pathPosixDirname(str);
  } while (isGlobby(str)); // remove escape chars and return result


  return str.replace(escaped, "$1");
};

function isEnclosure(str) {
  var lastChar = str.slice(-1);
  var enclosureStart;

  switch (lastChar) {
    case "}":
      enclosureStart = "{";
      break;

    case "]":
      enclosureStart = "[";
      break;

    default:
      return false;
  }

  var foundIndex = str.indexOf(enclosureStart);

  if (foundIndex < 0) {
    return false;
  }

  return str.slice(foundIndex + 1, -1).includes(slash);
}

function isGlobby(str) {
  if (/\([^()]+$/.test(str)) {
    return true;
  }

  if (str[0] === "{" || str[0] === "[") {
    return true;
  }

  if (/[^\\][{[]/.test(str)) {
    return true;
  }

  return isGlob(str);
}

export default exports;